<template>
	<div>
		<div class="kaoqin-wrap">
			<div class="kaoqin-top">
				<div class="left">员工上下班时间表</div>
				<div class="center">({{bumen_info.title}})</div>
				<div class="deta">{{date_start}} - {{date_end}}</div>
			</div>

			<div class="kaoqin-content">
				<div class="kaoqin--list" v-for="(user_item,index) in user_list" :key="index">
					<div class="kaoqin-tipBox-top">
						<div class="text">{{bumen_info.title}}</div>
						<!-- <div class="text">{{bumen_code}}</div> -->
						<div class="text">{{user_item.name}}</div>
					</div>
					<div class="kaoqin-historyBox">
						<div class="kaoqin-item" v-for="(item,index) in user_item.dayFrom" :key="index">
							<div class="day">{{(index + 1 < 10) ? '0' + (index + 1) : (index + 1)  }} 四</div>

						
						<template v-if="item.today <= current_date">
								<div class="timer" v-if="item.type == 1 || item.type == 4 || item.type == 6">
									<!-- 		08:00-12:06<br>
											13:30-17:30 -->
									<!-- 0-非下井 -->
									<!--4-三班倒早 -->
									<!--5-三班倒中 -->
									<!--6-三班倒晚 -->
									<template v-if="item.downType == 0 ||item.downType == 4  || item.downType == 5 || item.downType == 6">
										{{item.checkInTime | format_daka_time}} - {{item.checkOutTime | format_daka_time}}<br>
										{{item.checkInTime1 | format_daka_time}} - {{item.checkOutTime1 | format_daka_time}}
									</template>
								
									<!--  1-下井  -->
									<!-- 2-带班下井(零点） -->
									<!-- 3-带班下井（四点 -->
									<template v-if="item.downType == 1 || item.downType == 2 || item.downType == 3">
										{{item.downCheckInTime | format_daka_time}} - {{item.downCheckOutTime | format_daka_time}}<br>
										{{item.downCheckInTime1 | format_daka_time}} - {{item.downCheckOutTime1 | format_daka_time}}
									</template>
								
									<span class="green jieri absolute" v-if="item.type == 4">节日</span>
									<span class="blue xiajing absolute" v-if="item.downType == 1">下井</span>
									<span class="zise daiban absolute" v-if="item.downType == 2 || item.downType == 3">带班下井</span>
									<span class="orange yidi absolute" v-if="item.type == 6">异地打卡</span>
								</div>
								
								<div v-else class="flex-center-box"
									v-if="item.type == 2 ||item.type == 3 || item.type == 5 ||item.type == 7 ||item.type == 8 ||item.type == 9 ||item.type == 10 ||item.type == 11">
									<span v-if="item.type == 2" class="shijia orange">缺卡</span>
									<span v-if="item.type == 3" class="buka green">节日</span>
									<span v-if="item.type == 5" class="shijia orange">补卡</span>
									<span v-if="item.type == 7" class="shijia orange">事假</span>
									<span v-if="item.type == 8" class="shijia orange">病假</span>
									<span v-if="item.type == 9" class="shijia orange">工伤</span>
									<span v-if="item.type == 10" class="shijia orange">年休</span>
								</div>
						</template>
						

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	export default {
		components: {

		},
		data() {
			return {
				current_date: dayjs().format('YYYY-MM-DD'),
				
				bumen_id: '',
				month: '',
				bumen_info: {},
				title_month: '',
				date_start: '',
				date_end: '',
				//

				//
				bumen_title: '地质资源部',
				bumen_code: 'JG00029',
				//

				user_list: [{
					// downType: '',"downType": 0, //下井状态：0-非下井  1-下井 2-带班下井(零点）3-带班下井（四点） 
					// 4-三班倒早 5-三班倒中  6-三班倒晚

					//状态：
					// 1-正常打卡 
					//  2-旷工（缺卡）  
					//  3-节日 
					//  4-节日加班 
					//  5-补卡  
					// //6-异地打卡
					//  7-事假  
					//  8-病假  
					//  9-工伤 
					//  10-年假（年休） 
					//  11-补卡
				}],
				date_list: 30
			};
		},
		computed: {},
		watch: {

		},
		filters: {
			format_daka_time(str) {
				let time_format = (str || '').substring(11, 16) || ''
				return time_format
			}
		},
		created() {
			this.page_title = this.$route.meta.title
			this.month = this.$route.query.month || '';
			this.bumen_id = this.$route.query.id || '';

			this.set_date_info()
			this.query_bumen_info()
			this.setView()
		},
		mounted() {

		},
		methods: {
			set_date_info() {
				this.month_date = this.$route.query.month
				let day_info = dayjs(this.month_date) //
				//时间的开始
				this.date_start = day_info.startOf('month').format("YYYY-MM-DD")
				//时间的结束
				this.date_end = day_info.endOf('month').format("YYYY-MM-DD")
			},
			query_bumen_info() {

				this.$api("getCategory", {
					id: this.bumen_id
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.bumen_info = res.data;
					}
				});
			},

			setView() {
				console.log(this.forms)
				this.loading = true
				this.$api("kaoqin/getDepartForm", {
					deptId: this.bumen_id,
					month: this.month,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list;
						this.user_list = list
					} else {
						alertErr(res.msg)
					}
				});
			},

		},
	};
</script>

<style scoped lang="less">
	.kaoqin-wrap {
		background: #fff;
		padding: 43px 26px;
		border: 0.5px solid #E6E6E6;
		border-radius: 8px 8px 8px 8px;

		.kaoqin-content {
			.kaoqin--list {
				// margin-bottom: 20px;
				// border-bottom: 1px solid #0000;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					width: 99%;
					height: 2px;
					background-color: #000;
					bottom: -1px;
					left: 50%;
					transform: translateX(-50%);
				}

				.kaoqin-tipBox-top {
					border: 1px solid #D0D0D0;
					border-bottom: 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 13px 170px 13px 17px;

					.text {
						font-weight: bold;
						font-size: 14px;
					}
				}
			}

			.kaoqin-historyBox {
				display: flex;
				flex-wrap: wrap;
				border: 0.5px solid #D0D0D0;

				.green {
					color: #FFFFFF;
					font-size: 12px;
					background: #3BBF7D;
					border-radius: 11px 11px 11px 11px;
					padding: 2px 7px;
				}





				.kaoqin-item {
					width: 156px;
					width: calc(100% / 10);
					text-align: center;
					padding: 8px 0;
					font-size: 14px;
					color: #333;
					border: 1px solid #D0D0D0;
					border-top: none;
					border-left: none;

					.blue {
						background: #00B0FF;
					}

					.zise {
						border-radius: 5px !important;
						background-color: #6565F6;
						line-height: normal !important;
					}

					.orange {
						background: #FF5E00;
						border-radius: 5px !important;
						line-height: normal !important;
					}

					.flex-center-box {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 70%;

						.shijia {
							color: #FFFFFF;
							font-size: 12px;
							background: #FF5E00;
							border-radius: 99px !important;
							padding: 2px 7px;
						}
					}


					.day,
					.timer {
						color: #333;
						font-size: 14px;

					}

					.day {
						padding: 9px 0;
						border-bottom: 1px solid #CFCFCF;
					}

					.timer {
						padding: 9px 0 4px 0;
						line-height: 1.5;
						position: relative;

						.absolute {
							width: 38px;
							position: absolute;
							top: 9px;
							right: 0;
							color: #FFFFFF;
							font-size: 12px;
							border-radius: 11px;
							padding: 2px 7px;
						}
					}
				}
			}
		}


	}

	.kaoqin-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;

		.left {
			font-size: 18px;
			color: #333;
			font-weight: bold;
		}

		.center,
		.deta {
			font-size: 18px;
			color: #333;
		}

	}

	.kaoqin-tipBox-bottom {
		display: flex;
		align-items: center;
		border: 1px solid #D0D0D0;
		border-top: 0;

		.text {
			width: 390px;

			padding: 8px 23px;
			font-size: 14px;
			color: #333;
			font-size: 14px;
			color: #333;
			border-right: 1px solid #D0D0D0;

			&:last-child {
				border-right: 0;
			}
		}
	}
</style>